
/* eslint-disable */
import formatDate from "xe-utils/toDateString";
import { ref, reactive, onMounted, provide, computed, watch } from "vue";
import CloudFun, { defineComponent } from "@cloudfun/core";

export default defineComponent({
  props: {
    auth: {},
    data: {}
  },
  components: {},
  emits: ["refresh"],
  setup(props, emits) {
    const model = CloudFun.current?.model;

    const getAuth = () => {
      return model
        ?.dispatch("user/auth")
        .then((response: any) => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch((error: any) => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const entity = ref<any>();
    const auth = ref<any>();
    const reply = ref<string>("");

    onMounted(async () => {
      auth.value = await getAuth();
      //console.log(auth.value)
    });

    const reset = () => {
      reply.value = "";
    };

    const submitReply = () => {
      if (entity.value.consultationID) {
        //console.log(entity.value.consultationID);
        const data = {
          id: entity.value.consultationID,
          content: reply.value,
          field: ""
        };
        model
          ?.dispatch("consultation/insert", data)
          .then((response: any) => {
            if (response.status) {
              CloudFun.send("info", {
                subject: "執行成功",
                content: "完成留言"
              });
              reset();
              emits.emit("refresh");
            } else {
              CloudFun.send("error", {
                subject: "執行失敗",
                content: response.message
              });
            }
          })
          .catch((error: any) => {
            CloudFun.send("error", {
              subject: "執行失敗",
              content: error.message
            });
          });
      } else {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: "無法讀取主題內容"
        });
      }
    };

    watch(
      () => props.data,
      async value => {
        //console.log('watch', value)
        if (value) {
          entity.value = value;
          // console.log(entity.value)
          // entity.value.reply = await model?.dispatch("consultation/query", entity.value.consultationID)
          // console.log(entity.value.reply)   
        }
      },
      { immediate: true }
    );

    return {
      entity,
      formatDate,
      auth,
      imageUrl: `${process.env.VUE_APP_BACKEND_URL}/api/v1/Image`,
      reply,
      reset,
      submitReply
    };
  },
  methods: {
    showtextCotent(id: any) {
      cash(`#textCotent${id}`).removeClass("truncate");
      cash(`#showtextCotent${id}`).addClass("hidden");
      cash(`#hidetextCotent${id}`).removeClass("hidden");
    },
    hidetextCotent(id: any) {
      cash(`#textCotent${id}`).addClass("truncate");
      cash(`#showtextCotent${id}`).removeClass("hidden");
      cash(`#hidetextCotent${id}`).addClass("hidden");
    }
  }
});
