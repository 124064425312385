
import CloudFun, { computed, defineComponent, ref } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps, VxeTablePropTypes } from "vxe-table";
import CheckBoxList from "@/cloudfun/components/CheckBoxList.vue";
import ReplyModal from "@/components/reply-modal/Main.vue";
import formatDate from "xe-utils/toDateString";
import dayjs from "dayjs";
export default defineComponent({
  components: {
    Grid,
    CheckBoxList,
    ReplyModal
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const data = ref<any>({});
    const currentRow = ref<any>({});
    var printColumns = [{ field: "Name" }, { field: "Description" }];

    const rowClassName: VxeTablePropTypes.RowClassName = ({
      row,
      rowIndex
    }) => {
      //console.log(row)
      if (!row.isRead) {
        return "text-theme-10 font-bold";
      }
      return null;
    };

    const gridOptions: GridOptions = {
      id: "inquiry",
      title: "洽詢信箱",
      rowClassName: rowClassName,
      multiselect: false,
      canUpdate: false,
      canDelete: false,
      canRead: false,
      canCreate: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      printConfig: {
        sheetName: "洽詢信箱",
        columns: printColumns,
        modes: ["current", "selected", "all"]
      },
      exportConfig: {
        filename: "洽詢信箱",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns
      },
      columns: [
        {
          field: "lastUpdateTime",
          title: "時間",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          width: 250,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          },
          formatter: ({ cellValue }) =>
            dayjs(new Date(cellValue)).format("YYYY/MM/DD HH:mm")
        },
        {
          field: "title",
          title: "標題",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          minWidth: 200,
          // editRender: {
          //   name: "$input",
          //   immediate: true,
          //   attrs: { type: "text" }
          // },
          slots: { default: "title" }
        },
        {
          field: "name",
          title: "寄件者",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          width: 200,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        // {
        //   field: 'times',
        //   title: '洽談次數',
        //    align: "center",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: false,
        //   editRender: {
        //     name: '$input',
        //     immediate: true,
        //     attrs: { type: 'text' }
        //   },
        // },
        {
          field: "status",
          title: "狀態",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          width: 150,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
          //formatter: ({ cellValue }) => (cellValue ? "已讀" : "新留言")
        }
        // {
        //   field: "reply",
        //   title: "回覆",
        //   align: "center",
        //   fixed: "right",
        //   resizable: false,
        //   slots: { default: "reply" }
        // }
      ],
      promises: {
        query: model
          ? async params => {
              //console.log(params.pageSize, params.page)
              var result = await model.dispatch("message/query", {
                pageSize: params.pageSize,
                page: params.page
              });
              // console.log(result);
              return result;
            }
          : undefined
        // queryAll: model ? () => model.dispatch("message/query") : undefined,
        //   query: () => {
        //     return new Promise((resolve, reject) => {
        //       setTimeout(() => {
        //         resolve({
        //           "data":[
        //             {
        //               id: 1,
        //               createdTime: '2021/07/26 22:03',
        //               title: "S 111-11 產品名稱產品名稱",
        //               name: "XX-小花",
        //               times: "3",
        //               state: "新留言",
        //             },
        //             {
        //               id: 2,
        //               createdTime: '2021/07/26 22:03',
        //               title: "S 111-11 產品名稱產品名稱",
        //               name: "Jasper",
        //               times: "1",
        //               state: "新留言",
        //               isRead: true,
        //             },
        //             {
        //               id: 3,
        //               createdTime: '2021/07/26 22:03',
        //               title: "S 111-11 產品名稱產品名稱",
        //               name: "買家真實姓名",
        //               times: "2",
        //               state: "新留言",
        //             },
        //             {
        //               id: 4,
        //               createdTime: '2021/07/26 22:03',
        //               title: "S 111-11 產品名稱產品名稱",
        //               name: "Susan",
        //               times: "5",
        //               state: "已讀",
        //               isRead: true,
        //             },
        //             {
        //               id: 5,
        //               createdTime: '2021/07/26 22:03',
        //               title: "S 111-11 產品名稱產品名稱",
        //               name: "買家暱稱",
        //               times: "1",
        //               state: "已讀",
        //             },
        //           ],
        //           "totalCount":1,
        //         });
        //       }, 100);
        //     });
        //   },
      },
      modalConfig: { showFooter: true }
    };

    const formOptions: VxeFormProps = {
      titleWidth: 60,
      titleAlign: "right",
      items: [
        {
          field: "date",
          title: "時間",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "product",
          title: "詢問產品",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "buyer",
          title: "洽詢買家",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "times",
          title: "洽談次數",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "state",
          title: "狀態",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        }
      ],
      rules: {
        Name: [{ type: "string", required: true }]
      }
    };

    const permissionItems = computed(() => {
      const items: any[] = [];
      for (const permission of Object.values(
        model?.enums.SystemPermission || {}
      )) {
        if (permission.GroupName != null) {
          let group = items.find(e => e.name === permission.GroupName);
          if (!group)
            items.push(
              (group = { name: permission.GroupName, items: [], order: 0 })
            );
          group.items.push({
            name: permission.Name,
            value: permission.Value,
            order: permission.Order
          });
          if (group.order < permission.Order) group.order = permission.Order;
        } else
          items.push({
            name: permission.Name,
            value: permission.Value,
            order: permission.Order
          });
      }
      return items.sort((a: any, b: any) => a.order - b.order);
    });

    const editRow = async (row: any) => {
      const entity = row.messageId
        ? await model?.dispatch("message/find", row.messageId)
        : undefined;
      if (entity) {
        data.value = entity;
        currentRow.value = row;
        //console.log('editRow', data.value)
      } else {
        // CloudFun.send("error", {
        //   subject: "執行失敗",
        //   content: "讀取失敗",
        // });
      }
    };

    const refresh = async () => {
      await editRow(currentRow.value);
    };

    return {
      grid,
      gridOptions,
      formOptions,
      permissionItems,
      editRow,
      data,
      refresh
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    showIcon(title: string) {
      console.log("🚀 ~ file: Main.vue ~ line 449 ~ showIcon ~ title", title);
      const texts = ["會議通知", "會議室有"];
      let include = false;

      for (let i = 0; i < texts.length; i++) {
        const item = texts[i];
        if (title.indexOf(item) > -1) {
          include = true;
          break;
        }
      }
      console.log(
        "🚀 ~ file: Main.vue ~ line 449 ~ showIcon ~ include",
        !include
      );
      return !include;
    }
  }
});
