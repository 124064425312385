<template>
  <!-- 業務洽詢modal -->
  <div id="reply-modal" class="modal flex justify-center items-center">
    <div
      class="modal__content modal__content--xl max-h-screen overflow-y-auto relative w-screen p-6 max-w-screen-lg"
    >
      <a
        data-dismiss="modal"
        href="javascript:;"
        class="absolute right-0 top-0 mt-6 mr-6"
      >
        <xIcon class="w-8 h-8 text-gray-500" />
      </a>
      <div class="">
        <div class="font-bold text-lg my-4">洽詢內容瀏覽與回覆</div>
        <div class="p-3 border border-theme-5">
          <div class=" md:flex mb-5 pb-5 border-b border-theme-5">
            <div class=" mr-3">
              <img
                class=" max-w-full h-auto"
                :src="
                  entity?.product?.previewID
                    ? `${imageUrl}/${entity?.product?.previewID}`
                    : require(`@/midone/assets/images/placeholders/200x200.jpg`)
                "
                style=" width:150px"
                alt="產品圖"
              />
            </div>
            <div class="">
              <div class=" sm:flex mb-5">
                <div
                  class=" sm:w-32 min-w-32 md:text-right text-base mr-5 font-medium"
                >
                  洽詢產品
                </div>
                <div class="w-full">
                  {{ entity?.product?.name }}
                  <!-- <a
                    class=" text-base flex items-center text-theme-1"
                    href="#"
                    rel="noopener noreferrer"
                  >
                    {{ entity?.product?.name }}
                    <LogOutIcon class="ml-2 w-4 h-4" />
                  </a> -->
                </div>
              </div>
              <div class=" sm:flex mb-5">
                <div class=" sm:w-32 min-w-32 md:text-right text-base mr-5">
                  產品編號
                </div>
                <div class="w-full">
                  {{ entity?.product?.no }}
                </div>
              </div>
              <div class=" sm:flex mb-5">
                <div class=" sm:w-32 min-w-32 md:text-right text-base mr-5">
                  洽詢時間
                </div>
                <div class="w-full">
                  {{
                    entity?.createdTime
                      ? formatDate(
                          new Date(entity.createdTime),
                          "yyyy/MM/dd HH:mm"
                        )
                      : ""
                  }}
                </div>
              </div>
              <div class=" sm:flex">
                <div class=" sm:w-32 min-w-32 md:text-right text-base mr-5">
                  洽詢買家
                </div>
                <div class="w-full flex items-center">
                  <div class=" w-12 mr-2">
                    <img
                      alt="Midone Tailwind HTML Admin Template"
                      class="rounded-full max-w-full h-auto"
                      :src="
                        entity?.buyer?.avatarImageID
                          ? `${imageUrl}/${entity?.buyer?.avatarImageID}`
                          : require(`@/midone/assets/images/${
                              $f()[0].photos[0]
                            }`)
                      "
                    />
                  </div>
                  <div class=" font-medium text-base">
                    {{ entity?.buyer?.name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class=" text-base mb-5">
            <div class="text-2xl">{{ entity?.title }}</div>
            <div v-html="entity?.content?.replace('\n', '<br />')"></div>
          </div>
          <div
            v-if="entity?.file?.archiveID"
            class=" flex items-center flex-wrap mb-5"
          >
            <a
              href="#"
              class="button button--lg flex items-center text-white bg-theme-1 shadow-md mr-2 rounded-3xl"
            >
              <PaperclipIcon class="w-4 h-4 mr-2" />
              {{ entity?.file?.name ?? "附加檔案" }}
            </a>
          </div>

          <div v-if="entity?.consultationID" class="bg-gray-200 p-3">
            <div class="font-bold text-lg my-3">回覆</div>
            <div class=" sm:flex pb-5 mb-5 border-b border-theme-1">
              <div class="min-w-20 w-20 mr-3">
                <img
                  alt="Midone Tailwind HTML Admin Template"
                  class="rounded-full max-w-full h-auto"
                  :src="
                    auth?.avatarImageID
                      ? `${imageUrl}/${auth.avatarImageID}`
                      : require(`@/midone/assets/images/${$f()[0].photos[0]}`)
                  "
                />
              </div>
              <div class="w-full">
                <form class="">
                  <input
                    type="text"
                    class="intro-x input input--lg border border-gray-300 w-full mt-2 mb-3"
                    placeholder="新增回應"
                    v-model="reply"
                  />
                  <!-- <div class="">
                                        <a href="#" class=" flex items-center text-theme-1 mr-2 mb-3">
                                            <PaperclipIcon class="w-4 h-4 mr-2" />附加檔案僅限 .pdf 與 .jpg 檔,檔案大小上限 1 M
                                        </a>
                                    </div> -->
                  <div class=" flex items-center justify-end">
                    <button
                      type="button"
                      @click.prevent="reset"
                      class="button button--lg w-full lg:w-32 text-theme-1 bg-transparent lg:mr-3 align-top"
                    >
                      取消
                    </button>
                    <button
                      type="button"
                      @click.prevent="submitReply"
                      class="button button--lg w-full lg:w-32 text-white bg-theme-1  align-top"
                    >
                      回覆
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <template v-if="entity?.replies && entity?.replies.length">
              <div
                v-for="(item, index) in entity?.replies"
                :key="index"
                class=" flex pb-5 mb-5 border-theme-7"
              >
                <div class=" min-w-20 w-20 mr-3">
                  <img
                    alt="Midone Tailwind HTML Admin Template"
                    class="rounded-full max-w-full h-auto"
                    :src="
                      item.user?.avatarImageID
                        ? `${imageUrl}/${item.user?.avatarImageID}`
                        : require(`@/midone/assets/images/${$f()[0].photos[0]}`)
                    "
                  />
                </div>
                <div class="w-4/6 md:w-4/5 xl:w-5/6">
                  <div class="mb-2 flex items-center text-gray-600">
                    <b class=" text-theme-1 text-base">{{ item.user?.name }}</b>
                    {{ item.user?.contact_Title }}
                  </div>
                  <div class="mb-3 text-gray-600">
                    {{
                      item.createdTime
                        ? formatDate(
                            new Date(item.createdTime),
                            "yyyy/MM/dd HH:mm"
                          )
                        : ""
                    }}
                  </div>
                  <template v-if="item.message && item.message.length < 60">
                    <div class="mb-3 text-base truncate">
                      {{ item.message }}
                    </div>
                  </template>
                  <template v-else-if="item.message">
                    <div
                      :id="`textCotent${index}`"
                      class="mb-3 text-base truncate"
                    >
                      {{ item.message }}
                    </div>
                    <div class="">
                      <a
                        :id="`showtextCotent${index}`"
                        href="javascript:;"
                        @click="showtextCotent(index)"
                        class=" text-theme-7 flex items-center justify-end"
                      >
                        <ChevronDownIcon class="w-4 h-4 mr-2" />完整內容
                      </a>
                      <a
                        :id="`hidetextCotent${index}`"
                        href="javascript:;"
                        @click="hidetextCotent(index)"
                        class="hidden text-theme-7 flex items-center justify-end"
                      >
                        <ChevronUpIcon class="w-4 h-4 mr-2" />部分內容
                      </a>
                    </div>
                  </template>
                </div>
              </div>

              <!-- <div class=" flex pb-5 mb-5 border-theme-7">
                                <div class=" min-w-20 w-20 mr-3">
                                    <img alt="Midone Tailwind HTML Admin Template" class="rounded-full max-w-full h-auto" :src="
                                        require(`@/midone/assets/images/${$f()[0].photos[0]}`)
                                    " />
                                </div>
                                <div class="w-4/6 md:w-4/5 xl:w-5/6">
                                    <div class="mb-2 flex items-center text-gray-600">
                                        <b class=" text-theme-1 text-base">Lulu</b>．業務
                                    </div>
                                    <div class="mb-3 text-gray-600">
                                        2021/07/26 11:03
                                    </div>
                                    <div id="textCotent" class="mb-3 text-base truncate">
                                        這裡是業務Lulu的回覆內容,感謝您的詢問!因為回覆的留言特別多,所以需要進行收合所以需要進行收合所以需要進行收合所以需要進行收合
                                    </div>
                                    <div class="">
                                        <a id="showtextCotent" href="javascript:;" @click="showtextCotent()" class=" text-theme-7 flex items-center justify-end">
                                            <ChevronDownIcon class="w-4 h-4 mr-2" />完整內容
                                        </a>
                                        <a id="hidetextCotent" href="javascript:;" @click="hidetextCotent()" class="hidden text-theme-7 flex items-center justify-end">
                                            <ChevronUpIcon class="w-4 h-4 mr-2" />部分內容
                                        </a>
                                    </div>
                                </div>
                            </div> -->
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
/* eslint-disable */
import formatDate from "xe-utils/toDateString";
import { ref, reactive, onMounted, provide, computed, watch } from "vue";
import CloudFun, { defineComponent } from "@cloudfun/core";

export default defineComponent({
  props: {
    auth: {},
    data: {}
  },
  components: {},
  emits: ["refresh"],
  setup(props, emits) {
    const model = CloudFun.current?.model;

    const getAuth = () => {
      return model
        ?.dispatch("user/auth")
        .then((response: any) => {
          if (response.status) {
            return response.payload;
          } else {
            CloudFun.send("error", {
              subject: "讀取失敗",
              content: response.message
            });
          }
        })
        .catch((error: any) => {
          CloudFun.send("error", {
            subject: "讀取失敗",
            content: error.message
          });
        });
    };

    const entity = ref<any>();
    const auth = ref<any>();
    const reply = ref<string>("");

    onMounted(async () => {
      auth.value = await getAuth();
      //console.log(auth.value)
    });

    const reset = () => {
      reply.value = "";
    };

    const submitReply = () => {
      if (entity.value.consultationID) {
        //console.log(entity.value.consultationID);
        const data = {
          id: entity.value.consultationID,
          content: reply.value,
          field: ""
        };
        model
          ?.dispatch("consultation/insert", data)
          .then((response: any) => {
            if (response.status) {
              CloudFun.send("info", {
                subject: "執行成功",
                content: "完成留言"
              });
              reset();
              emits.emit("refresh");
            } else {
              CloudFun.send("error", {
                subject: "執行失敗",
                content: response.message
              });
            }
          })
          .catch((error: any) => {
            CloudFun.send("error", {
              subject: "執行失敗",
              content: error.message
            });
          });
      } else {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: "無法讀取主題內容"
        });
      }
    };

    watch(
      () => props.data,
      async value => {
        //console.log('watch', value)
        if (value) {
          entity.value = value;
          // console.log(entity.value)
          // entity.value.reply = await model?.dispatch("consultation/query", entity.value.consultationID)
          // console.log(entity.value.reply)   
        }
      },
      { immediate: true }
    );

    return {
      entity,
      formatDate,
      auth,
      imageUrl: `${process.env.VUE_APP_BACKEND_URL}/api/v1/Image`,
      reply,
      reset,
      submitReply
    };
  },
  methods: {
    showtextCotent(id: any) {
      cash(`#textCotent${id}`).removeClass("truncate");
      cash(`#showtextCotent${id}`).addClass("hidden");
      cash(`#hidetextCotent${id}`).removeClass("hidden");
    },
    hidetextCotent(id: any) {
      cash(`#textCotent${id}`).addClass("truncate");
      cash(`#showtextCotent${id}`).removeClass("hidden");
      cash(`#hidetextCotent${id}`).addClass("hidden");
    }
  }
});
</script>

<style lang="scss">
.min-w-20 {
  min-width: 5rem;
}
</style>
