<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">業務洽詢信箱</h2>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-10 mt-10">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <!-- <div class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center ml-1 sm:mr-2 mt-2 xl:mt-0">
            <input
              type="text"
              class="input w-full sm:w-40 xxl:w-full mt-2 sm:mt-0 border"
              v-model.trim="grid.keyword"
              placeholder="請輸入關鍵字"
              @keyup.enter="grid.refresh"
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              type="button"
              class="button w-full sm:w-16 bg-theme-1 text-white"
              @click="grid.refresh"
            >
              搜尋
            </button>
          </div>
        </div> -->
        <div class="flex mt-2 sm:mt-0">
          <!-- <button
            class="button w-1/2 sm:w-auto flex items-center border text-gray-700 dark:bg-dark-5 dark:text-gray-300"
            @click="grid.onPrint({ mode: 'curret' })"
          >
            <FontAwesome icon="print" class="w-4 h-4 mr-2" /> 列印
          </button> -->
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden mt-2 inquiry-wrap">
        <grid
          ref="grid"
          v-bind="gridOptions"
          @removeSelectedRows="hideBatchDropDown()"
        >
          <template #reply="{ row }">
            <div v-if="showIcon(row.title)">
              <a
                @click="editRow(row)"
                href="javascript:;"
                data-toggle="modal"
                data-target="#reply-modal"
                class=" inline-flex items-center"
              >
                <MessageSquareIcon class="w-6 h-6 text-theme-10" />
              </a>
            </div>
          </template>
          <template #title="{ row }">
            <div class="text-left">
              <a
                @click="editRow(row)"
                href="javascript:;"
                data-toggle="modal"
                data-target="#reply-modal"
                class=" inline-flex items-center"
              >
                {{ row.title }}
              </a>
            </div>
          </template>
          <!-- <template #modal="{ row, submit, reset }">
            <vxe-form
              ref="form"
              v-bind="formOptions"
              :data="row"
              @reset="reset"
              @submit="submit"
            >
            </vxe-form>
          </template>
          <template #modal-footer>
            <vxe-button type="submit" status="primary" content="確認" @click="$refs.form.validate((errMap) => { if (errMap === undefined) $refs.form.dispatchEvent('submit'); })"></vxe-button>
            <vxe-button type="reset" content="重置" @click="$refs.form.dispatchEvent('reset')"></vxe-button>
          </template> -->
          <template #filter-name="{ column, $panel }">
            <div
              v-for="(option, index) in column.filters"
              :key="index"
              class="flex item-center justify-center"
            >
              <input
                type="checkbox"
                class="input input--switch border mt-4 ml-2"
                :checked="option.checked"
                @input="grid.changeFilterEvent(event, option, $panel, true)"
              />
              <div class="relative m-2">
                <div
                  class="absolute rounded-l w-10 h-full flex items-center justify-center bg-gray-100 dark:bg-dark-1 dark:border-dark-4 border text-gray-600"
                >
                  {{ option.label }}
                </div>
                <input
                  v-model.trim="option.data"
                  :disabled="!option.checked"
                  type="text"
                  class="input pl-12 w-full border col-span-4"
                  placeholder="請輸入文字"
                  @input="grid.changeFilterEvent(event, option, $panel)"
                />
              </div>
            </div>
          </template>
        </grid>
      </div>
    </div>
    <!-- END: HTML Table Data -->
  </div>
  <!-- 業務洽詢modal -->
  <ReplyModal :data="data" @refresh="refresh" />
</template>

<script lang="ts">
import CloudFun, { computed, defineComponent, ref } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps, VxeTablePropTypes } from "vxe-table";
import CheckBoxList from "@/cloudfun/components/CheckBoxList.vue";
import ReplyModal from "@/components/reply-modal/Main.vue";
import formatDate from "xe-utils/toDateString";
import dayjs from "dayjs";
export default defineComponent({
  components: {
    Grid,
    CheckBoxList,
    ReplyModal
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const data = ref<any>({});
    const currentRow = ref<any>({});
    var printColumns = [{ field: "Name" }, { field: "Description" }];

    const rowClassName: VxeTablePropTypes.RowClassName = ({
      row,
      rowIndex
    }) => {
      //console.log(row)
      if (!row.isRead) {
        return "text-theme-10 font-bold";
      }
      return null;
    };

    const gridOptions: GridOptions = {
      id: "inquiry",
      title: "洽詢信箱",
      rowClassName: rowClassName,
      multiselect: false,
      canUpdate: false,
      canDelete: false,
      canRead: false,
      canCreate: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      printConfig: {
        sheetName: "洽詢信箱",
        columns: printColumns,
        modes: ["current", "selected", "all"]
      },
      exportConfig: {
        filename: "洽詢信箱",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns
      },
      columns: [
        {
          field: "lastUpdateTime",
          title: "時間",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          width: 250,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          },
          formatter: ({ cellValue }) =>
            dayjs(new Date(cellValue)).format("YYYY/MM/DD HH:mm")
        },
        {
          field: "title",
          title: "標題",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          minWidth: 200,
          // editRender: {
          //   name: "$input",
          //   immediate: true,
          //   attrs: { type: "text" }
          // },
          slots: { default: "title" }
        },
        {
          field: "name",
          title: "寄件者",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          width: 200,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
        },
        // {
        //   field: 'times',
        //   title: '洽談次數',
        //    align: "center",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: false,
        //   editRender: {
        //     name: '$input',
        //     immediate: true,
        //     attrs: { type: 'text' }
        //   },
        // },
        {
          field: "status",
          title: "狀態",
          align: "center",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          width: 150,
          editRender: {
            name: "$input",
            immediate: true,
            attrs: { type: "text" }
          }
          //formatter: ({ cellValue }) => (cellValue ? "已讀" : "新留言")
        }
        // {
        //   field: "reply",
        //   title: "回覆",
        //   align: "center",
        //   fixed: "right",
        //   resizable: false,
        //   slots: { default: "reply" }
        // }
      ],
      promises: {
        query: model
          ? async params => {
              //console.log(params.pageSize, params.page)
              var result = await model.dispatch("message/query", {
                pageSize: params.pageSize,
                page: params.page
              });
              // console.log(result);
              return result;
            }
          : undefined
        // queryAll: model ? () => model.dispatch("message/query") : undefined,
        //   query: () => {
        //     return new Promise((resolve, reject) => {
        //       setTimeout(() => {
        //         resolve({
        //           "data":[
        //             {
        //               id: 1,
        //               createdTime: '2021/07/26 22:03',
        //               title: "S 111-11 產品名稱產品名稱",
        //               name: "XX-小花",
        //               times: "3",
        //               state: "新留言",
        //             },
        //             {
        //               id: 2,
        //               createdTime: '2021/07/26 22:03',
        //               title: "S 111-11 產品名稱產品名稱",
        //               name: "Jasper",
        //               times: "1",
        //               state: "新留言",
        //               isRead: true,
        //             },
        //             {
        //               id: 3,
        //               createdTime: '2021/07/26 22:03',
        //               title: "S 111-11 產品名稱產品名稱",
        //               name: "買家真實姓名",
        //               times: "2",
        //               state: "新留言",
        //             },
        //             {
        //               id: 4,
        //               createdTime: '2021/07/26 22:03',
        //               title: "S 111-11 產品名稱產品名稱",
        //               name: "Susan",
        //               times: "5",
        //               state: "已讀",
        //               isRead: true,
        //             },
        //             {
        //               id: 5,
        //               createdTime: '2021/07/26 22:03',
        //               title: "S 111-11 產品名稱產品名稱",
        //               name: "買家暱稱",
        //               times: "1",
        //               state: "已讀",
        //             },
        //           ],
        //           "totalCount":1,
        //         });
        //       }, 100);
        //     });
        //   },
      },
      modalConfig: { showFooter: true }
    };

    const formOptions: VxeFormProps = {
      titleWidth: 60,
      titleAlign: "right",
      items: [
        {
          field: "date",
          title: "時間",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "product",
          title: "詢問產品",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "buyer",
          title: "洽詢買家",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "times",
          title: "洽談次數",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        },
        {
          field: "state",
          title: "狀態",
          span: 24,
          itemRender: {
            name: "$input",
            props: { placeholder: "請輸入文字", clearable: true },
            attrs: { type: "text" }
          }
        }
      ],
      rules: {
        Name: [{ type: "string", required: true }]
      }
    };

    const permissionItems = computed(() => {
      const items: any[] = [];
      for (const permission of Object.values(
        model?.enums.SystemPermission || {}
      )) {
        if (permission.GroupName != null) {
          let group = items.find(e => e.name === permission.GroupName);
          if (!group)
            items.push(
              (group = { name: permission.GroupName, items: [], order: 0 })
            );
          group.items.push({
            name: permission.Name,
            value: permission.Value,
            order: permission.Order
          });
          if (group.order < permission.Order) group.order = permission.Order;
        } else
          items.push({
            name: permission.Name,
            value: permission.Value,
            order: permission.Order
          });
      }
      return items.sort((a: any, b: any) => a.order - b.order);
    });

    const editRow = async (row: any) => {
      const entity = row.messageId
        ? await model?.dispatch("message/find", row.messageId)
        : undefined;
      if (entity) {
        data.value = entity;
        currentRow.value = row;
        //console.log('editRow', data.value)
      } else {
        // CloudFun.send("error", {
        //   subject: "執行失敗",
        //   content: "讀取失敗",
        // });
      }
    };

    const refresh = async () => {
      await editRow(currentRow.value);
    };

    return {
      grid,
      gridOptions,
      formOptions,
      permissionItems,
      editRow,
      data,
      refresh
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    showIcon(title: string) {
      console.log("🚀 ~ file: Main.vue ~ line 449 ~ showIcon ~ title", title);
      const texts = ["會議通知", "會議室有"];
      let include = false;

      for (let i = 0; i < texts.length; i++) {
        const item = texts[i];
        if (title.indexOf(item) > -1) {
          include = true;
          break;
        }
      }
      console.log(
        "🚀 ~ file: Main.vue ~ line 449 ~ showIcon ~ include",
        !include
      );
      return !include;
    }
  }
});
</script>

<style lang="scss">
// .inquiry-wrap {
//   .vxe-table--body {
//     tbody {
//       tr:nth-child(-n+3):nth-child(n+1) {
//         @apply text-theme-10 font-bold
//       }
//     }
//   }
// }
</style>
